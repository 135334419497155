@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'ALSSchlangeslabBold';
    src: url('../public/assets/fonts/399485_0_0.eot');
    src: url('../public/assets/fonts/399485_0_0.eot?#iefix') format('embedded-opentype'),
    url('../public/assets/fonts/399485_0_0.woff2') format('woff2'),
    url('../public/assets/fonts/399485_0_0.woff') format('woff'),
    url('../public/assets/fonts/399485_0_0.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'ALSSchlangeslabRegular';
    src: url('../public/assets/fonts/399485_1_0.eot');
    src: url('../public/assets/fonts/399485_1_0.eot?#iefix') format('embedded-opentype'),
    url('../public/assets/fonts/399485_1_0.woff2') format('woff2'),
    url('../public/assets/fonts/399485_1_0.woff') format('woff'),
    url('../public/assets/fonts/399485_1_0.ttf') format('truetype');
    font-display: block;
}

@layer base {
    html {
        font-family: 'Karla', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-size: 1rem;
    }
}

.apexcharts-legend {
    flex-direction: column !important;
}